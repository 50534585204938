
<template>
  <div>
    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item>免费使用次数编辑</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="container">
      <el-form label="添加信息" :model="form" prop="form"  :inline="true" :rules="rules" ref="form">

        <el-form-item prop="counter" label="免费使用次数"label-width="120px">
          <el-input style="width: 600px;" v-model="form.counter"   placeholder="请输入免费使用次数"></el-input>
        </el-form-item><br>

        <el-form-item prop="concat" label="管理员手机号"label-width="120px">
          <el-input style="width: 600px;" v-model="form.concat"   placeholder="请输入管理员手机号"></el-input>
        </el-form-item><br>


      </el-form>

      <!-- 表单提交和取消-->
      <div style="text-align:center;" >
        <el-button @click="cancel('form')">取 消</el-button>
        <el-button type="primary" @click="submit('form')">提 交</el-button>
      </div>

    </div>



  </div>
</template>

<script>
import Editor from "@/components/editor";

export default {
  name: "CounterEdit",
  components: {Editor},
  data() {
// 验证手机号的规则
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      value:"",
      isEdit :false,
      token:"",
      //定义规则，判断是否有输入标题
      rules: {
        concat: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],

      },

      //表单 中的信息
      form:{
        id:"",
        counter:"",
        concat:"",
      },

    }
  },
  created() {
    this.token = window.sessionStorage.getItem('token')

  },
  mounted() {
    this.form = this.$route.params ;
    this.isEdit = this.$route.params.isEdit

  },

  methods: {
    getMinWidth(){
      return (this.screenWidth -900)+"px"
    },

    cancel(formName) {
      this.$refs[formName].resetFields();
    },

    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if(this.isEdit){
            this.$http.post('/counter/update', this.form)
              .then(res => {
                if (res.data.code === 2000) {
                  this.$notify.success({
                    title: "成功",
                    message: res.data.message
                  });
                  this.cancel('form');
                }else {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message
                  });
                }
              }).catch((err) =>{
              console.log("提交数据错误:",err);
            })
          }
          else{

          }
        }
      });
    },

  }
}


</script>
<style lang="scss" scoped>
.el-breadcrumb{
  margin-bottom: 20px;
}
.container{
  margin-top: 20px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9fafc;

}

</style>
