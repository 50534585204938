<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
          <img src="../assets/logo.png">
      </div>
      <!-- 登录表单区域  :model 表示在表单中输入的所有内容都会绑定到这个loginForm上-->
            <!-- :rules  验证输入的规则 -->
      <el-form  ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
        <!-- 用户名 iconfont 使我们定义的css文件了名字 -->
        <el-form-item prop="username">
          <!-- v-model 表示双向绑定-->
          <el-input v-model=loginForm.username prefix-icon="iconfont icon-user"> </el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item  prop="password">
          <el-input  v-model=loginForm.password type="password" prefix-icon="iconfont icon-3702mima"></el-input>
        </el-form-item>

        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>

      </el-form>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      loginForm:{
        username: "", //admin
        password: "",//123456
      },

      // 这是表单的验证规则对象
      loginFormRules: {
          // 验证用户名是否合法
          username: [
            { required: true,  message: '请输入登录名称', trigger: 'blur'  },
            { min: 3,  max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
           ],
          // 验证密码是否合法
          password: [
            { required: true, message: '请输入登录密码',  trigger: 'blur' },
            { min: 6,  max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
          ]
        }
      }
  },

  methods: {
    //点击重置
    resetLoginForm(){
      this.$refs.loginFormRef.resetFields();
    },
    //登录 前的验证（输入格式是否合法）
    login(){
      // this.$refs.loginFormRef.validate  表示调用组件 loginFormRef 中的 validata方法，而loginFormRef表示 el-form
      this.$refs.loginFormRef.validate(async (valid)=>{
        if(!valid) return;
        //async 和await 要一对  await 表示是等待方法的执行
        // { data: res }  表示从返回的json字典中，只要 data这字段中的数据并把他赋值给 res
        const { data: res } = await this.$http.post("/user/login", {
          userName:this.loginForm.username,
          password:this.loginForm.password
          })
        console.log(res)
        if(res.code !==2000) {
          this.$message.error("登录失败！")
          return
        }
        this.$message.success("登录成功")

        // 1. 将登录成功之后的 token，保存到客户端的 sessionStorage 中
        //   1.1 项目中出了登录之外的其他API接口，必须在登录之后才能访问
        //   1.2 token 只应在当前网站打开期间生效，所以将 token 保存在 sessionStorage 中

        //localStorage 和 sessionStorage 属性允许在浏览器中存储 key/value 对的数据。
        // sessionStorage  在关闭窗口或标签页之后将会删除这些数据。
        // localStorage 在浏览器窗口关闭后还保留数据
        window.sessionStorage.setItem('token', res.data.token)
        window.sessionStorage.setItem("activePath","teacherInfo")
        //登录成功后跳转到 指定页面
        this.$router.push('/home')
      })
    }


  }
}
</script>

<!--scoped 表示该样式只在当前节点生效  -->
<!--lang="scss"  需要 npm install sass-loader   和 npm install node-sass-->
<style lang="scss" scoped>
  .login_container {
    background-color: #2b4b6b;
    height: 100%;
      .login_box {
        width: 450px;
        height: 300px;
        background-color: #fff;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .avatar_box{
          width: 130px;
          height: 130px;
          border-radius: 50%;
          border: 1px solid #eee;
          box-shadow: 0 0 10px #ddd;
          padding: 10px;
          position: absolute;
          left: 50%;
          transform: translate(-50%,-50%);
          background-color: #fff;
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #eeeeee;
          }
        }
      }
  }
  .login_form{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    .btns{
      display: flex;
      justify-content: flex-end;
    }
  }

</style>
