<!--导入mysql.db到输入据库中 1,创建一个数据库mydb,2.use mydb;3.set names utf8; 4.source  xxx/xx/mydb.sql-->
<template>
  <div id="app" >
<!--  路由占位符，在router 中定义的组件，将会展示在这里-->
     <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'

}
</script>

<style lang="scss" scoped>

</style>


