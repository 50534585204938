<template>
  <div>

    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item>会员信息</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 结果列表 -->
    <el-card class="container">
      <el-table :data="infoList" stripe style="width: 100%" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">


        <el-table-column prop="phone" label="手机号" width="400">
          <template slot-scope="scope">
             {{scope.row.phone}}
          </template>
        </el-table-column>

        <el-table-column prop="registertime" label="注册时间" width="400">
          <template slot-scope="scope">
            {{scope.row.registertime }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="openEditUI(scope.row)" type="primary" icon="el-icon-edit" size="mini" circle></el-button>
            <el-button @click="deleteOne(scope.row.id)" type="danger" icon="el-icon-delete" size="mini" circle></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 分页组件 -->
    <el-pagination  v-if="total !== 0"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="searchModel.page"
                    :page-sizes="[5, 10, 20, 50]"
                    :page-size="searchModel.limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total" >
    </el-pagination>

  </div>
</template>

<script>
export default {
  name: "VipInfo",

  data(){
    return{
      infoList: [],
      total : 0,

      searchModel: {
        page: 1,
        limit: 5
      },

    }
  },
  mounted() {
    this.getInfoList();
  },

  methods:{

    getInfoList() {
      this.$http.get(`/viper/list/${this.searchModel.page}/${this.searchModel.limit}` )
        .then((response) => {
          console.log("---->",response)
          if(response.data.code !==2000){
            this.$message.error(response.data.message)
            return
          }
          this.infoList = response.data.data.list;
          this.total = response.data.data.total;
        });
    },

    // 当前分页中展示的item个数
    handleSizeChange(pageSize) {
      this.searchModel.limit = pageSize;
      this.getInfoList();
    },
    //页面切换（切换到其他分页）
    handleCurrentChange(pageNo) {
      this.searchModel.page = pageNo;
      this.getInfoList();
    },

    openEditUI(info) {
      this.$router.push({name:"VipEdit",params:{
          isEdit:true,
          id:info.id,
          phone: info.phone,
        }})

    },

    deleteOne(id){
      this.$confirm(`您确认删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log("======>" ,id)
        this.$http.delete(`/viper/delete/${id}` ).then(response=>{
          this.$message({
            type: 'success',
            message: response.data.message,
          });
          this.getInfoList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb{
  margin-bottom: 20px;
}

.container{
  margin-top: 20px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  background-color: #f9fafc;
}


</style>

