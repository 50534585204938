<template>

  <el-container class="home-container">
    <!--1. 头部区域 -->
    <el-header>
      <div>
        <img src="../assets/left_logo.png" style="width: 50px;height: 50px;margin-left: 20px"></img>
        <span>销售后台管理系统</span>
      </div>
      <el-button type="info" @click="logout"> 退出</el-button>
    </el-header>
    <!-- 2. 页面主体区域 -->
    <el-container>
      <!--2.1 左边菜单区域 -->
      <el-aside width="200px">
        <!-- 菜单区域 :router表示启动路由模式，当激活导航时以 index 作为 path 进行路由跳转-->
        <el-menu :default-active="activePath" :router="true" :unique-opened="true" background-color="#545c64" text-color="#fff"  active-text-color="#409EFF">
          <!-- 一级菜单   :index 动态的数据绑定-->
          <el-submenu :index="item.id+''" v-for="item in menlist" :key="item.id">
            <!-- 一级菜单中的内容-->
            <template slot="title">
              <!-- 文本-->
              <span>{{item.authName}}</span>
            </template>
            <!-- 二级菜单-->
            <el-menu-item  @click="saveNavState( subItem.path)" :index="subItem.path" v-for="subItem in item.children" :key="subItem.id">
              <template slot="title">
                <!-- 图标 属性前加个: 表示动态绑定(:就是v-bind的缩写）-->
                <i :class="iconsObj[subItem.id]"></i>
                <!-- 文本-->
                <span>{{ subItem.authName }}</span>
              </template>
            </el-menu-item>

          </el-submenu>

        </el-menu>
      </el-aside>
      <!-- 2.2 右边区域 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>

  </el-container>

</template>

<script>
export default {
  name: 'Home',
  data(){
    return{
      //左侧菜单数据
      menlist:[
        {
          id:"1",
          authName: "销售社区管理",
          children:[
            {
              id:"11",
              authName: "销售社区查看",
              path:"CommunityInfo"
           },
            {
              id:"12",
              authName: "销售社区编辑",
              path:"CommunityEdit"
            },
          ]
        },
        {
          id:"2",
          authName: "销售测评管理",
          children:[
            {
              id:"21",
              authName: "销售测评查看",
              path:"QuestionInfo"
            },
            {
              id:"22",
              authName: "销售测评编辑",
              path:"QuestionEdit"
            },
            {
              id:"23",
              authName: "销售等级查看",
              path:"LevelInfo"
            },
            {
              id:"24",
              authName: "销售等级编辑",
              path:"LevelEdit"
            },
          ]
        },{
          id:"3",
          authName: "签约之路管理",
          children:[
            {
              id:"31",
              authName: "签约之路查看",
              path:"SignInfo"
            },
            {
              id:"32",
              authName: "签约之路编辑",
              path:"SignEdit"
            },
          ]
        },
        {
          id:"4",
          authName: "销售互动管理",
          children:[
            {
              id:"41",
              authName: "销售互动查看",
              path:"InteractionInfo"
            },
            {
              id:"42",
              authName: "销售互动编辑",
              path:"InteractionEdit"

            },
          ]
        },
        {
          id:"5",
          authName: "会员管理",
          children:[
            {
              id:"51",
              authName: "会员查看",
              path:"VipInfo"
            },
            {
              id:"52",
              authName: "会员编辑",
              path:"VipEdit"
            },
            {
              id:"53",
              authName: "免费使用次数",
              path:"CounterInfo"
            }

          ]
        }
        ],
      //左侧菜单对应的图标
      iconsObj: {
        '11': 'iconfont icon-user',
        '12': 'iconfont icon-user',
        '21': 'iconfont icon-user',
        '22': 'iconfont icon-user',
        '23': 'iconfont icon-user',
        '24': 'iconfont icon-user',
        '31': 'el-icon-folder-opened',
        '32': 'el-icon-folder-add',
        '41': 'iconfont icon-shangpin',
        '42': 'iconfont icon-danju',
        '51': 'iconfont icon-shangpin',
        '52': 'iconfont icon-danju',
        '53': 'iconfont icon-danju',
      },
      activePath: "",

    }
  },
  methods: {
    logout () {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },

    //子菜单被点击的时候调用
    saveNavState(activePath){
      window.sessionStorage.setItem("activePath",activePath)
      this.activePath = activePath

    },
  },
  created () {
    this.activePath = window.sessionStorage.getItem("activePath")
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  height: 100%;

  .el-header {
    background-color: #373d41;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    color: #fff;
    font-size: 30px;

    > div {
      display: flex;
      align-items: center;

      > span {
        padding-left: 15px;
      }
    }
  }

  .el-aside {
    background-color: #333744;
    .el-menu{
      border-right: none;
    }
  }

  .el-main {
    background-color: #eaedf1;
  }
  .iconfont{
    margin-right: 10px;
  }
  .toggle-collapse{
    background-color: #4a5064;
    font-size: 10px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
  }
}

</style>
