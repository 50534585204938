import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login'
import Home from '@/components/Home'
import CommunityEdit from "@/components/community/CommunityEdit";
import CommunityInfo from "@/components/community/CommunityInfo";
import SignEdit from "@/components/sign/SignEdit";
import SignInfo from "@/components/sign/SignInfo";
import InteractionEdit from "@/components/interaction/InteractionEdit";
import InteractionInfo from "@/components/interaction/InteractionInfo";

import LevelEdit from "@/components/level/LevelEdit";
import LevelInfo from "@/components/level/LevelInfo";
import QuestionEdit from "@/components/questions/QuestionEdit";
import QuestionInfo from "@/components/questions/QuestionInfo";
import VipEdit from "@/components/vip/VipEdit";
import VipInfo from "@/components/vip/VipInfo";
import CounterInfo from "@/components/vip/CounterInfo";
import CounterEdit from "@/components/vip/CounterEdit";
Vue.use(VueRouter)
// 定义路由规则
const routes = [
  //1.登录界面，第一个界面
  {
    // 指定访问的路径
    path: '/login',
    // 指定要展示的组件
    component: Login
  },
  {  //  重定向， 当打开的是 /目录的时候，直接重定向到 /login中
    path: '/',
    redirect: '/login'
  },
  //2、进入 home ，home包含侧边栏，重定向到第一个所需要的界面
  { path: '/home',
    component: Home ,
    redirect: "/CommunityInfo",  //打开 home界面这里会重定向到他的子组件welcome中，welcome 会显示在 home中的 <router-view></router-view>  那里
    children: [
      {  path: '/CommunityInfo',name:"CommunityInfo", component: CommunityInfo },
      {  path: '/CommunityEdit', name:"CommunityEdit",component: CommunityEdit },
      {  path: '/SignInfo',name:"SignInfo", component: SignInfo },
      {  path: '/SignEdit', name:"SignEdit",component: SignEdit },
      {  path: '/InteractionInfo',name:"InteractionInfo", component: InteractionInfo },
      {  path: '/InteractionEdit', name:"InteractionEdit",component: InteractionEdit },
      {  path: '/LevelInfo',name:"LevelInfo", component: LevelInfo },
      {  path: '/LevelEdit', name:"LevelEdit",component: LevelEdit },
      {  path: '/QuestionInfo',name:"QuestionInfo", component: QuestionInfo },
      {  path: '/QuestionEdit', name:"QuestionEdit",component: QuestionEdit },
      {  path: '/VipInfo',name:"VipInfo", component: VipInfo },
      {  path: '/VipEdit', name:"VipEdit",component: VipEdit },
      {  path: '/CounterInfo', name:"CounterInfo",component: CounterInfo },
      {  path: '/CounterEdit', name:"CounterEdit",component: CounterEdit },
    ]
  },
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫 ,如果用户没有登录，但是直接通过url访问特定页面，需要重新导航到登录页面
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转
  if (to.path === '/login') return next() //如果是要访问 login界面，直接放行
  const tokenStr = window.sessionStorage.getItem('token') //获取token
  if (!tokenStr) return next('/login') //如果token是空，直接到login界面
  next() //如果token不为空，放行（想去哪去哪）
})

//其他页面中通过 this.$router 可以访问到这个路由
export default router
