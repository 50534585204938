import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
//导入全局样式
import "./assets/css/global.css"
//导入样式图标
import "./assets/fonts/iconfont.css"

//富文本相关 样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

//引入 axios
import axios from 'axios'
// 通过axios 请求拦截器添加token 保证拥有获取数据的权限
//发送数据请求的时候，先调用该回调，对请求做预处理
axios.interceptors.request.use(config => {
  //为请求对象添加token验证的authorization字段
  //console.log(config)
  config.headers.Authorization = window.sessionStorage.getItem('token')
  config.withCredentials = false
  return config
})
Vue.prototype.$http = axios  //每个组件都可以通过this 直接访问http 并发起 axios请求 this.$http就可以请求了
Vue.prototype.screenWidth = document.body.clientWidth// 屏幕宽度
Vue.prototype.screenHeight = document.body.clientHeight// 屏幕高度,
Vue.prototype.$BASE_URL = "https://www.xiaoshou.fun:8085/"
Vue.prototype.$BASE_URL_SYS = "https://www.xiaoshou.fun:8085/sys/"
axios.defaults.baseURL = Vue.prototype.$BASE_URL_SYS
Vue.config.productionTip = false

import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
