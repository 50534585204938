<template>
  <div>

    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item>免费使用次数信息</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 结果列表 -->
    <el-card class="container">
      <el-table :data="infoList" stripe style="width: 100%" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">

        <el-table-column prop="counter" label="免费使用次数" width="400">
          <template slot-scope="scope">
             {{scope.row.counter}}
          </template>
        </el-table-column>

        <el-table-column prop="concat" label="管理员手机号" width="400">
          <template slot-scope="scope">
            {{scope.row.concat}}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="openEditUI(scope.row)" type="primary" icon="el-icon-edit" size="mini" circle></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

  </div>
</template>

<script>
export default {
  name: "CounterInfo",

  data(){
    return{
      infoList: [],
      total : 0,

    }
  },
  mounted() {
    this.getInfoList();
  },

  methods:{

    getInfoList() {
      this.$http.get(`/counter/list` )
        .then((response) => {
          if(response.data.code !==2000){
            this.$message.error(response.data.message)
            return
          }
          this.infoList = response.data.data.list;
          this.total = response.data.data.total;
        });
    },

    openEditUI(info) {
      this.$router.push({name:"CounterEdit",params:{
          isEdit:true,
          id:info.id,
          counter: info.counter,
          concat:info.concat,
        }})

    },
  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb{
  margin-bottom: 20px;
}

.container{
  margin-top: 20px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  background-color: #f9fafc;
}


</style>

