
<template>
  <div>
    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item>销售社区编辑</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="container">
      <el-form label="添加信息" :model="form" prop="form"  :inline="true" :rules="rules" ref="form">

        <el-form-item prop="subtitle1" label="一级目录"label-width="120px">
          <el-input style="width: 600px;" v-model="form.subtitle1"   placeholder="请输入一级目录"></el-input>
        </el-form-item><br>

        <el-form-item prop="subtitle2" label="二级目录"label-width="120px">
          <el-input style="width: 600px;" v-model="form.subtitle2"   placeholder="请输入二级目录"></el-input>
        </el-form-item><br>

        <!-- 富文本信息-->
        <el-form-item prop="info" label="内容" label-width="60px">
          <el-card style="height: 410px">
            <editor ref="wang_editor" v-model:value="form.detail" :style="{marginBottom: 100+'px',minWidth: getMinWidth() ,maxWidth: getMinWidth() }" :value="form.detail"></editor>
          </el-card>
        </el-form-item>

      </el-form>

      <!-- 表单提交和取消-->
      <div style="text-align:center;" >
        <el-button @click="cancel('form')">取 消</el-button>
        <el-button type="primary" @click="submit('form')">提 交</el-button>
      </div>

    </div>



  </div>
</template>

<script>
import Editor from "@/components/editor";

export default {
  name: "CommunityEdit",
  components: {Editor},
  data() {
    return {
      value:"",
      isEdit :false,
      token:"",

      //定义规则，判断是否有输入标题
      rules: {
        subtitle1: [
          { required: true, message: '请输入一级标题', trigger: 'blur' },
        ],
        subtitle2: [
          { required: true, message: '请输入二级标题', trigger: 'blur' },
          ],

      },

      //表单 中的信息
      form:{
        id:"",
        subtitle1:"",
        subtitle2: "",
        detail:"",
      },

    }
  },
  created() {
    this.token = window.sessionStorage.getItem('token')

  },
  mounted() {
    this.form = this.$route.params ;
    this.$refs.wang_editor.updateInput( this.$route.params.detail)
    this.isEdit = this.$route.params.isEdit

  },

  methods: {
    getMinWidth(){
      return (this.screenWidth -900)+"px"
    },

    cancel(formName) {
      this.$refs[formName].resetFields();
      this.$refs.wang_editor.clearContent()
    },

    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if(this.isEdit){
            this.$http.post('/community/update', this.form)
              .then(res => {
                if (res.data.code === 2000) {
                  this.$notify.success({
                    title: "成功",
                    message: res.data.message
                  });
                  this.cancel('form');
                }else {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message
                  });
                }
              }).catch((err) =>{
              console.log("提交数据错误:",err);
            })
          }
          else{
            this.$http.post('/community/add', this.form)
              .then(res => {
                console.log("-->",res)
                if (res.data.code === 2000) {
                  this.$notify.success({
                    title: "成功",
                    message: res.data.message
                  });
                  this.cancel('form');
                }else {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message
                  });
                }
              }).catch((err) =>{
              console.log("提交数据错误:",err);
            })

          }
        }
      });
    },

  }
}


</script>
<style lang="scss" scoped>
.el-breadcrumb{
  margin-bottom: 20px;
}
.container{
  margin-top: 20px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9fafc;

}

</style>
