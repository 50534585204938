<template>
  <div>
    <div ref="editor"></div>
  </div>
</template>

<script>
import E from 'wangeditor';
export default {

  name: 'editor',
  data() {
    return {
      editor :""
    };
  },
  props: {
    value: '',
  },
  methods:{
    updateInput(content) {
      this.editor.txt.html(content)
    },
    clearContent(){
      this.editor.txt.clear()
    }
  } ,
  mounted() {
    this.editor = new E(this.$refs.editor);
    this.editor.config.menus = [
      'head',
      'bold', // 粗体
      'fontSize', // 字号
      'fontName', // 字体
      'italic', // 斜体
      'underline', // 下划线
      'strikeThrough', // 删除线
      'foreColor', // 文字颜色
      'link', // 插入链接
      'list', // 列表
      'justify', // 对齐方式
      'quote', // 引用
      'image', // 插入图片
      //'location', // 位置
    ];
    this.editor.config.onchange = html => {
      this.$emit('input', html);
    };
    //editor.config.debug = true;
    this.editor.config.uploadImgServer = this.$BASE_URL_SYS+'/upload/pic/';
    this.editor.config.uploadImgHeaders = {
      Authorization: window.sessionStorage.getItem('token')
    };
    this.editor.config.uploadImgMaxSize = 10 * 1024 * 1024; // 将图片大小限制为 10M
    this.editor.config.uploadFileName = 'file'; //后端接受上传文件的参数名
    this.editor.config.uploadImgMaxLength = 1; // 限制一次最多上传 1 张图片
    this.editor.config.showLinkImg = false; //隐藏网络图片上传
    this.editor.config.uploadImgHooks = {
      fail: (xhr, editor, result) => {
        // 插入图片失败回调
        console.log("failure->",result);
      },
      success: (xhr, editor, result) => {
        // 图片上传成功回调
        console.log("success->",result);
      },
      timeout: (xhr, editor) => {
        // 网络超时的回调
        console.log('网络超时');
      },
      error: (xhr, editor) => {
        // 图片上传错误的回调
        console.log('上传错误');
      },
      //回显
      customInsert: (insertImg, result, editor) => {
        let url = this.$BASE_URL +result.data
        insertImg(url);
      },
    };
    this.editor.create();
    this.editor.txt.html(this.value);
  },

};
</script>

<style scoped>
</style>

